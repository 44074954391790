import { useEffect, useMemo, useState, useCallback } from "react";
import * as anchor from "@project-serum/anchor";

import styled from "styled-components";
import { Container, Paper, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { PublicKey } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import {
    awaitTransactionSignatureConfirmation,
    CandyMachineAccount,
    CANDY_MACHINE_PROGRAM,
    getCandyMachineState,
    mintOneToken,
} from "./candy-machine";
import { AlertState } from "./utils";
import { Header } from "./Header";
import { MintButton } from "./MintButton";
import { GatewayProvider } from "@civic/solana-gateway-react";
import { CrossMintButton } from "@crossmint/client-sdk-react-ui";

const ConnectButton = styled(WalletDialogButton)`
    width: 100%;
    height: 60px;
    margin-top: 10px;
    margin-bottom: 5px;
    background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
    color: white;
    font-size: 16px;
    font-weight: bold;
`;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    txTimeout: number;
    rpcHost: string;
}

const Home = (props: HomeProps) => {
    const [isUserMinting, setIsUserMinting] = useState(false);
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: "",
        severity: undefined,
    });

    const rpcUrl = props.rpcHost;
    const wallet = useWallet();

    const anchorWallet = useMemo(() => {
        if (!wallet || !wallet.publicKey || !wallet.signAllTransactions || !wallet.signTransaction) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const refreshCandyMachineState = useCallback(async () => {
        if (!anchorWallet) {
            return;
        }

        if (props.candyMachineId) {
            try {
                const cndy = await getCandyMachineState(anchorWallet, props.candyMachineId, props.connection);
                setCandyMachine(cndy);
            } catch (e) {
                console.log("There was a problem fetching Candy Machine state");
                console.log(e);
            }
        }
    }, [anchorWallet, props.candyMachineId, props.connection]);

    const onMint = async () => {
        try {
            setIsUserMinting(true);
            document.getElementById("#identity")?.click();
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                const mintTxId = (await mintOneToken(candyMachine, wallet.publicKey))[0];

                let status: any = { err: true };
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        true
                    );
                }

                if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message: "Congratulations! Mint succeeded!",
                        severity: "success",
                    });
                } else {
                    setAlertState({
                        open: true,
                        message: "Mint failed! Please try again!",
                        severity: "error",
                    });
                }
            }
        } catch (error: any) {
            let message = error.msg || "Minting failed! Please try again!";
            if (!error.msg) {
                if (!error.message) {
                    message = "Transaction Timeout! Please try again.";
                } else if (error.message.indexOf("0x137") !== -1) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf("0x135") !== -1) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: "error",
            });
        } finally {
            setIsUserMinting(false);
        }
    };

    useEffect(() => {
        refreshCandyMachineState();
    }, [anchorWallet, props.candyMachineId, props.connection, refreshCandyMachineState]);

    return (
        <Container style={{ marginTop: 100 }}>
            <Container maxWidth="sm" style={{ position: "relative" }}>
                <h1 style={{ color: "#FF5D5D" }}>One Planet For Ukraine</h1>
                <p
                    style={{
                        color: "#777",
                        fontSize: "0.75rem",
                        fontWeight: "bold",
                    }}
                >
                    100% OF FUNDS RAISED GO TO VERIFIED NON-PROFITS THAT HELP UKRAINE
                </p>
                <img src="/assets/hero.png" alt="illustration of nft" />
                <p>Mint to donate 0.33 SOL ($30-$35)</p>
                <div
                    style={{
                        maxWidth: "360px",
                        margin: "auto",
                        display: "block",
                    }}
                >
                    <Paper
                        style={{
                            padding: 24,
                            backgroundColor: "#151A1F77",
                            borderRadius: 6,
                        }}
                    >
                        {!wallet.connected ? (
                            <ConnectButton>Connect Wallet</ConnectButton>
                        ) : (
                            <>
                                <Header candyMachine={candyMachine} />
                                <MintContainer>
                                    {candyMachine?.state.isActive &&
                                    candyMachine?.state.gatekeeper &&
                                    wallet.publicKey &&
                                    wallet.signTransaction ? (
                                        <GatewayProvider
                                            wallet={{
                                                publicKey: wallet.publicKey || new PublicKey(CANDY_MACHINE_PROGRAM),
                                                //@ts-ignore
                                                signTransaction: wallet.signTransaction,
                                            }}
                                            gatekeeperNetwork={candyMachine?.state?.gatekeeper?.gatekeeperNetwork}
                                            clusterUrl={rpcUrl}
                                            options={{ autoShowModal: false }}
                                        >
                                            <MintButton
                                                candyMachine={candyMachine}
                                                isMinting={isUserMinting}
                                                onMint={onMint}
                                            />
                                        </GatewayProvider>
                                    ) : (
                                        <MintButton
                                            candyMachine={candyMachine}
                                            isMinting={isUserMinting}
                                            onMint={onMint}
                                        />
                                    )}
                                </MintContainer>
                            </>
                        )}
                        <CrossMintButton
                            style={{ margin: "0 auto", width: "100%" }}
                            collectionTitle="One Planet For Ukraine"
                            collectionDescription="Fundraiser for nonprofits helping peacekeeping efforts and assisting war victims in Ukraine. Created by the Belugies team in partnership with crossmint."
                            collectionPhoto="https://www.oneplanetforukraine.com/assets/hero.png"
                        />
                    </Paper>
                </div>
                <p>Or Donate SOL/ETH directly</p>
                <p>Donate Solana: yxZVw37ejppPwwYJP5gnJuidX39TcZmDxpNJrVSrbyT</p>
                <p>Donate Ethereum: 0x5d5A3BcC41B8F37442bb7E01805D83bc079069F0</p>
                <p
                    className="bold"
                    style={{
                        marginTop: "40px",
                    }}
                >
                    How do I know the money will actually go to help the people of Ukraine?
                </p>
                <p>
                    This fundraiser is created by the Belugies NFT team. We have <span className="bold">VERIFIED</span>{" "}
                    donations amounting to $242,064.69 <span className="bold">SO FAR</span>.
                </p>
                <p>We have a tracker you can view below.</p>
                <p>
                    We will not take 1 single penny of this for ourselves, and Crossmint will donate their credit card
                    fees as well. Literally every single cent will go to nonprofits that are helping Ukraine in these
                    horrible times.
                </p>
                <p>
                    Here is our website: <a href="https://belugies.com">https://belugies.com</a>
                </p>

                <p>
                    And here is{" "}
                    <a href="https://docs.google.com/spreadsheets/d/1zKz7ff1F9e8yQExBt5PHFHiYpMZmdpCek0wVVgnftlY/edit#gid=0">
                        our donation tracker with proof
                    </a>
                </p>
                <p
                    className="bold"
                    style={{
                        marginTop: "40px",
                    }}
                >
                    Where will the money be donated?
                </p>
                <p>
                    This money will be used to provide humanitarian assistance to those who are most at-risk and need
                    support. Our intended beneficiaries are children, the elderly, disabled, persons with illnesses, and
                    so on. The ones who need it most. This money will not be used for military purposes and we want that
                    to be very clear. This is not about politics or publicity. This is about supporting our fellow human
                    beings of current and future generations who are certain to be traumatized by the atrocities of war.
                </p>
                <p>Here are a few of the nonprofits will be donating to:</p>
                <ul style={{ textAlign: "left" }}>
                    <li>
                        <a href="https://voices.org.ua/en/">Voices of Children</a>
                    </li>
                    <li>
                        <a href="http://monstrov.org/rekvizity/">KORPORATSIYA MONSTRIV</a>
                    </li>
                    <li>
                        <a href="https://donate.redcrossredcrescent.org/ua/donate/~my-donation?context_form_id=form_b3c4871eefc9230b8da009dbff2b6bb2_6217dfa8bdd752.01150150">
                            Red Cross
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/donate/1137971146948461/">Humanitarian Aid to Ukraine</a>
                    </li>
                    <li>
                        <a href="https://4agc.com/fundraiser_pages/e9aca7e4-13d5-4e67-b6bd-548f94822793#.Yhjn4OjMK3C">
                            Support Ukrainian Hospitals
                        </a>
                    </li>
                </ul>

                <p
                    style={{
                        color: "#777",
                        fontSize: "0.7rem",
                        marginTop: "40px",
                    }}
                >
                    <img
                        src="https://www.crossmint.io/assets/crossmint/logo.png"
                        alt="logo"
                        style={{
                            width: "12px",
                            marginRight: "5px",
                            position: "relative",
                            top: "2px",
                        }}
                    />
                    Powered by Crossmint
                </p>
            </Container>

            <Snackbar
                open={alertState.open}
                autoHideDuration={6000}
                onClose={() => setAlertState({ ...alertState, open: false })}
            >
                <Alert onClose={() => setAlertState({ ...alertState, open: false })} severity={alertState.severity}>
                    {alertState.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Home;
